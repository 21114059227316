import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function OrderResult() {
    const [title, setTitle] = useState()
    const [message, setMessage] = useState()
    let { status } = useParams();

    const history = useNavigate()
    useEffect(() => {
        console.log(status)
        switch (status) {
            case "nopay":
                setTitle("Ops....")
                setMessage('Anda belum melakukan pembayaran, silahkan pilih tombol "lanjutkan pembayaran" di daftar tansaksi ada untuk menyelesaikan transaksi anda')
                break;
            case "success":
                setTitle("Selamat...!")
                setMessage('Transaksi anda berhasil, silahkan lihat daftar transaksi anda')
                break;
            case "error":
                setTitle("Ops...!")
                setMessage('Terjadi kesalahan pada pembayaran anda, silahkan cek di daftar transaksi')
                break;
            case "pending":
                setTitle("Terima kasih...")
                setMessage('Kami menunggu konfirmasi dari pembayaran anda dari pihak bank, silahkan cek di daftar transaksi anda')
                break;
            default:
                break;
        }
    }, [])
    return (
        <div className="h-screen w-full">
            <div className="h-screen container mx-auto p-5 pt-20 pb-24  overflow-auto no-scrollbar flex justify-center items-center">
                <div className='w-3/4 border rounded-md h-1/2 flex flex-col items-center justify-center p-5'>
                    <div className='text-2xl font-bold mb-5'>{title}</div>
                    <div className='text-md text-center mb-5'>{message}</div>
                    <button className='btn' onClick={()=>history('/transaction/list')}>Lihat Daftar Transaksi</button>
                </div>
            </div>
        </div>
    )
}

export default OrderResult