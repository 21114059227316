import { useState, useEffect,useContext } from "react";
import { Link } from 'react-router-dom'
import SocialButton from "../SocialButton";
import Logo from '../../assets/mic_logo.png'
import { navs } from '../../jsons/navs'
import { session, clearSession, setSession } from '../../utils/AuthSession'
import { gapi } from 'gapi-script';
import { GoogleLogin, GoogleLogout } from 'react-google-login'
import { Navigate } from 'react-router-dom'
import { AiFillHome, AiOutlineHistory, AiOutlineUser, AiOutlineDollarCircle, AiOutlineGift } from "react-icons/ai";
import {
    useNavigate
} from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

function ShoppingSummary(props) {
    const {primaryColor,primaryColorHover} = useContext(ThemeContext) 
    return (
        <div>
            <div className="container fixed z-50 h-40 min-w-lg -translate-x-1/2 border border-none bottom-16 left-1/2 rounded-tl-xl rounded-tr-xl block md:hidden" style={{backgroundColor:`${primaryColor}`}}>
                <div className="flex flex-col justify-center items-center w-full">
                    <div className="text-white pt-3">Ringkasan Belanja</div>
                    <div className="flex justify-between border-b w-full p-3 text-white">
                        <div>Total</div>
                        <div>Rp. {Intl.NumberFormat('en-ID').format(props.total ? props.total : 0)}</div>
                    </div>
                    <div className="flex justify-center item mt-3">
                        <button className="btn w-96" onClick={()=>props.onClick()}>Beli{props.count > 0 ? ` (${props.count})` : ``}</button>
                    </div>

                </div>
            </div>

            <div className="border border-none hidden md:block rounded-xl pb-3" style={{backgroundColor:`${primaryColor}`}}>
                <div className="flex flex-col justify-center items-center">
                    <div className="text-white pt-3">Ringkasan Belanja</div>
                    <div className="flex justify-between border-b w-full p-3 text-white">
                        <div>Total</div>
                        <div>Rp. {Intl.NumberFormat('en-ID').format(props.total ? props.total : 0)}</div>
                    </div>
                    <div className="flex justify-center item m-3">
                        <button className="btn w-40 hover:opacity-50 text-white" style={{backgroundColor:`${primaryColorHover}`}} onClick={()=>props.onClick()}>Beli{props.count > 0 ? ` (${props.count})` : ``}</button>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ShoppingSummary