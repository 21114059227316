import React, { useState, useEffect, useRef, useContext } from 'react'
import { Avatar, Button } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session, clearSession } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useForm, SubmitHandler } from "react-hook-form"
import Select from 'react-select';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ThemeContext } from '../../context/ThemeContext'

function Profile() {

    const [authSession, setAuthSession] = useState(session())
    const [profile, setProfile] = useState(null)
    const [imgError, setImgError] = useState(false)
    const [profileImage, setProfileImage] = useState(null)
    const [address, setAddress] = useState([])
    const [province, setProvince] = useState([])
    const [city, setCity] = useState([])
    const [selectedAddress, setSelectedAddress] = useState()
    const [dialogTitle, setDialogTitle] = useState()
    const [isCalculatingWidth, setIsCalculatingWidth] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState();
    const [menuWidth, setMenuWidth] = useState();
    const [citySelected, setCitySelected] = useState()
    const [provinceSelected, SetProvinceSelected] = useState()

    const [typeSubmit, setTypeSubmit] = useState('create')
    const [proccessing, setProccessing] = useState(true)
    const [inputValue, setInputValue] = useState('')

    const {primaryColor} = useContext(ThemeContext)

    const history = useNavigate()
    const selectInputRef = useRef();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data) => {
        console.log(data)
        setProccessing(false)
        switch (typeSubmit) {
            case "create":
                const params = {
                    access: 'auth',
                    method: API.ADDRESS_CREATE.type,
                    url: API.ADDRESS_CREATE.url,
                    reqBody: {
                        address_name: data.address_name,
                        province_id: data.province_id,
                        province_name: data.province_name,
                        city_id: data.city_id,
                        city_type: data.city_type,
                        city_name: data.city_name,
                        full_address: data.address,
                        postal_code: data.postal_code
                    }
                }
                try {
                    const response = await Api(params)
                    if (response.status) {
                        toast(response.message);
                        document.getElementById('address_modal').close()
                        initAddress()
                        setProccessing(true)
                    } else {
                        toast.error(response.message)
                    }
                } catch (error) {
                    toast.error(error.message)
                }


                break;

            case "edit": 
            const params2 = {
                access: 'auth',
                method: API.ADDRESS_EDIT.type,
                url: API.ADDRESS_EDIT.url,
                reqBody: {
                    address_id: data.address_id,
                    address_name: data.address_name,
                    province_id: data.province_id,
                    province_name: data.province_name,
                    city_id: data.city_id,
                    city_type: data.city_type,
                    city_name: data.city_name,
                    full_address: data.address,
                    postal_code: data.postal_code
                }
            }
            try {
                const response = await Api(params2)
                if (response.status) {
                    toast(response.message);
                    document.getElementById('address_modal').close()
                    initAddress()
                    setProccessing(true)
                } else {
                    toast.error(response.message)
                }
            } catch (error) {
                toast.error(error.message)
            }
                break;
            default:
                break;
        }
    }



    const ref = useRef()
    useEffect(() => {
        init();
        initAddress()
        initProvince()
    }, [])
    const init = async () => {
        const params = {
            access: 'auth',
            method: API.PROFILE.type,
            url: API.PROFILE.url,
            reqBody: {}
        }
        const response = await Api(params);
        if (response) {
            setProfile(response.data)
            setProfileImage(response.data.image_profile)
        }
    }

    const initProvince = async () => {
        const params = {
            access: 'auth',
            method: API.PROVINCE.type,
            url: API.PROVINCE.url,
            reqBody: {}
        }
        try {
            const response = await Api(params);
            if (response.status) {
                setProvince(response.data.map((p) => {
                    return {
                        value: p.province_id,
                        label: p.province
                    }
                }))
            } else {
                toast.error(response.message)
            }
        } catch (error) {

        }
    }

    // console.log('province', province)

    const initAddress = async () => {
        const params = {
            access: 'auth',
            method: API.ADDRESS.type,
            url: API.ADDRESS.url,
            reqBody: {}
        }
        try {
            const response = await Api(params);
            if (response.status) {
                setAddress(response.data)
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }

    }

    const handleLogout = async () => {
        await clearSession()
        history(0)

    }

    const onError = (e) => {
        console.log("error")
        setProfileImage(ProfilUnknow)
    }

    const handleAddAddress = async (title, type) => {
        if (address.length < process.env.REACT_APP_MAX_ADDRESS) {
            setDialogTitle(title)
            setTypeSubmit(type)
            setValue("address_name", "")
            setValue("province_id", "")
            setValue("province_name", "")
            setValue("city_id", "")
            setValue("city_type", "")
            setValue("name", "")
            setValue("address", "")
            setValue("postal_code", "")
            setValue("address_id", "")
            document.getElementById('address_modal').showModal()
        } else {
            toast.error(`Maksimal daftar adalah ${process.env.REACT_APP_MAX_ADDRESS}`)
        }
    }
    // console.log('profile',profileImage)

    const handleChangeProvince = async (e) => {
        console.log(e)

        const params = {
            access: 'auth',
            method: API.CITY.type,
            url: API.CITY.url,
            reqBody: {
                province_id: e.value
            }
        }
        try {
            const response = await Api(params);
            if (response.status) {
                setCity(response.data.map((c) => {
                    return {
                        value: c.city_id,
                        label: `${c.type} ${c.city_name}`,
                        city_type: c.type
                    }
                }))
                setValue('province_id', e.value)
                setValue('province_name', e.label)
                setValue('city_id', null)
                setValue('city_name', null)
                SetProvinceSelected(e)
                setCitySelected(null)
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleChangeCity = (e) => {
        console.log(e)
        setCitySelected(e)
        setValue('city_id', e.value)
        setValue('city_name', e.label)
        setValue('city_type', e.city_type)
    }

    // console.log('city', city)
    const handleDeleteAddress = async (id) => {
        toast(id)
    }

    const deleteAddress = (id) => {
        Swal.fire({
            title: "Yakin akan hapus alamat?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
            customClass: {
                title: 'text-base',
                confirmButton: 'bg-slate-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded',
                cancelButton: 'bg-grey-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
            }
        }).then( async (result) => {
            console.log(result)
            if(result.isConfirmed){
                const params = {
                    access: 'auth',
                    method: API.ADDRESS_DELETE.type,
                    url: API.ADDRESS_DELETE.url,
                    reqBody: {
                        address_id: id,
                        
                    }
                }

                try {
                    const response = await Api(params)
                    if(response.status){
                        toast(response.message)
                        initAddress()
                    }else{
                        toast.error(response.message)
                    }
                } catch (error) {
                    toast.error(error.message)
                }
            }
        })
    }

    const editAddress = async(data) => {
        // console.log(data)
        setDialogTitle('Sunting Alamat')
        setTypeSubmit('edit')
        setValue("address_id", data.address_id)
        setValue("address_name", data.address_name)
        setValue("province_id", data.province_id)
        setValue("province_name", data.province)
        
        setValue("city_type", data.city_type)
        setValue("address", data.full_address)
        setValue("postal_code", data.postal_code)
        SetProvinceSelected({value:data.province_id,label:data.province_name})
        await handleChangeProvince({value:data.province_id,label:data.province_name})
        setCitySelected({value:data.city_id,label:`${data.city_type} ${data.city_name}`,city_type:data.city_type})
        setValue("city_id", data.city_id)
        setValue("city_name",data.city_name)
        document.getElementById('address_modal').showModal()
    }

    console.log(errors)
    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <div className="h-screen container mx-auto p-5 pt-16 pb-24">
                <div className='overflow-auto no-scrollbar'>
                    <h1 className="text-xl font-semibold">Profile</h1>
                    <div className='flex gap-5 pt-5'>
                        <div className='w-1/4'>
                            <img src={profileImage || ProfilUnknow}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = ProfilUnknow;
                                }}
                                alt="profile" className='rounded-full' />
                        </div>
                        <div className='w-3/4'>
                            <div className='font-semibold text-lg'>
                                {profile?.firstname ? profile.firstname : 'Belum ada nama'} {profile?.lastname ? profile.lastname : ''}
                            </div>
                            <div>
                                {profile?.phone}
                            </div>
                            <div className='text-orange-600 text-xs'>
                                {profile?.email ? profile.email : 'belum mengisi email'}
                            </div>
                            <div className='pt-5'>
                                <button className='btn normal-case bg-white text-slate-500 shadow-md shadow-slate-400 hover:opacity-50 border-none'>Perbaharui data</button>
                            </div>
                        </div>

                    </div>
                    <div className='addresses mt-3 mb-3'>
                        <div className='flex justify-between items-center mb-3'>
                            <div className='font-bold mt-1 mb-1 text-slate-500'>Daftar Alamat</div>
                            <button 
                                className='btn text-white hover:opacity-50' 
                                onClick={() => { handleAddAddress('Tambah Alamat', 'create') }}
                                style={{backgroundColor: `${primaryColor}`}}
                            >
                                Tambah Alamat
                            </button>
                        </div>

                        {address.map((a, i) => (
                            <div key={i} className='flex flex-col gap-3 border rounded-xl p-3 relative'>
                                <div className='font-semibold'>{a.address_name}</div>
                                <div className='text-sm'>{a.full_address}</div>
                                <div className='text-sm'>{a.city_name} - {a.province_name}</div>
                                <div className='text-sm'>{a.postal_code}</div>
                                <div className='MenuAddress absolute bottom-0 right-0 z-50'>
                                    <button className='btn text-white hover:opacity-50' style={{backgroundColor: `${primaryColor}`}} onClick={() => editAddress(a)}><AiOutlineEdit /></button>
                                    <button className='btn text-white hover:opacity-50' style={{backgroundColor: `${primaryColor}`}} onClick={() => deleteAddress(a.address_id)}><AiOutlineDelete /></button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-end'>
                        <Button size="md" onClick={handleLogout}>Logout</Button>
                    </div>
                </div>
            </div>

            <dialog id="address_modal" className="modal">
                <div className="modal-box w-full">
                    <h3 className="font-bold text-lg">{dialogTitle}</h3>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid'>
                                <div className='pt-3'>Nama</div>
                                <input className="input border-gray-300" placeholder='Nama tujuan lenggkap' defaultValue="" {...register("address_name", { required: true })} />
                            </div>
                            <div className='prov pt-3'>
                                <div className=''>Provinsi</div>
                                <Select
                                    {...register("province_id", { required: true })}
                                    value={provinceSelected}
                                    onChange={handleChangeProvince}
                                    options={province}
                                    placeholder="Select..."
                                />
                                <input type="hidden" {...register("province_name", { required: true })} />
                            </div>

                            <div className='city pt-3'>
                                <div className=''>Kota/Kabupaten</div>
                                <Select
                                    {...register("city_id", { required: true })}
                                    value={citySelected}
                                    onChange={handleChangeCity}
                                    options={city}
                                    placeholder="Select..."
                                    isDisabled={!provinceSelected}
                                />
                                <input type="hidden" {...register("city_name", { required: true })} />
                            </div>

                            <div className='address pt-3'>
                                <div className=''>Alamat lengkap</div>
                                <textarea className="textarea w-full border-gray-300" placeholder="Alamat lengkap ..." rows="4" {...register("address", { required: true })}></textarea>
                            </div>

                            <div className='grid mb-3'>
                                <div className=''>Kode Pos</div>
                                <input className="input border-gray-300" placeholder='Kode pos' defaultValue="" {...register("postal_code", { required: true })} />
                            </div>

                            <div className='flex justify-end gap-2'>
                                <input type="submit" className='btn' disabled={!proccessing} />
                                <button type="button" className='btn' onClick={()=>{document.getElementById('address_modal').close()}}>Batal</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default Profile