import React, { useState, useEffect, useContext } from 'react'
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler } from "react-hook-form"
import { auth, session, store } from '../../utils/AuthSession'
import MidT from '../../components/MidT'
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../../context/ThemeContext';

const CryptoJS = require("crypto-js");

function PreOrder() {
    // console.log(useLocation().search)
    const [parameters, setParameters] = useState(queryString.parse(useLocation().search))
    const [transaction, setTransaction] = useState()
    const [preview, setPreview] = useState()
    const [store_payment, setStorePayment] = useState([])
    const [Store, setStore] = useState()
    const [loadMidtrans,setLoadMidtrans] = useState(false)
    const [token,setToken] = useState(null)

    const history = useNavigate()
    const {primaryColor} = useContext(ThemeContext)
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data) => {
        // console.log(data)
    }

    useEffect(() => {
        store().then((data) => {
            setStore(data)
            setStorePayment(data.data.data.store_payment_method_information.map(x => {
                return {
                    value: x.ms_payment_id,
                    label: x.ms_payment_name,
                    ms_payment_identifier: x.ms_payment_identifier
                }
            }))
        })

        init()
    }, [])

    const init = async () => {

        // console.log(parameters.o)
        const safeEncrypted = parameters.o
        const encryptedBase64 = safeEncrypted.replace(/-/g, '+').replace(/_/g, '/');
        const decrypted = CryptoJS.AES.decrypt(encryptedBase64, process.env.REACT_APP_SECRET_CODE, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        var originalText = decrypted.toString(CryptoJS.enc.Utf8);
        setTransaction(JSON.parse(originalText))
    }

    const FnTransaction = async () => {
        const params = {
            access: 'auth',
            url: API.TRANSACTION.url,
            method: API.TRANSACTION.type,
            reqBody: transaction
        }
        try {
            const response = await Api(params)
            console.log('response', response)
            if (response.success) {
                console.log('Preview', response.data)
                setPreview(response.data)


            }


        } catch (error) {
            toast.error(error.message)
        }
    }
    useEffect(() => {
        console.log('Transaction', transaction)
        FnTransaction()
    }, [transaction])

    const handlePay = async () => {
        transaction.preview_fee = false
        const params = {
            access: 'auth',
            url: API.TRANSACTION.url,
            method: API.TRANSACTION.type,
            reqBody: transaction
        }
        try {
            const response = await Api(params)
            console.log('response', response)
            if (response.status) {
                setToken(response.token)
                console.log(response.token)
            }
        } catch (error) {
            toast.error(error.message)
        }

    }

    const handlePaySuccess = (result) => {
        history(`/order/status/success`)
    }

    const handlePayError = (result) => {
        history(`/order/status/error`)
    }

    const handlePayPending = (result) => {
        history(`/order/status/pending`)
    }

    const handlePayClose = (result) => {
        setToken(null)
        history(`/order/status/nopay`)
    }

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <Helmet>
                <script src={process.env.REACT_APP_MIDTRANS_SNAP_SCRIPT} async></script>
            </Helmet>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="h-screen container mx-auto p-5 pt-20 pb-24  overflow-auto no-scrollbar">
                    <div className='border border-solid p-4 rounded-xl mb-3'>

                        <div className='h1 font-bold text-slate-600'>PESANAN</div>
                        <div className='flex flex-col gap-0'>
                            <div className='basis-full md:basis-1/2 '>
                                {preview?.current_order?.map((d, i) => (
                                    <div key={i} className='flex flex-row form-control border-b border-b-solid border-b-slate-200 mt-'>
                                        <div className="cursor-pointer label basis-1/12">
                                            <img src={d?.item_image} alt="" />
                                        </div>
                                        <div className='basis-3/4 pl-3'>

                                            <div className='w-[280px] whitespace-nowrap overflow-hidden text-ellipsis'>{d?.item_name}</div>
                                            <div className='font-bold text-sm'>{d?.quantity} x Rp. {Intl.NumberFormat('en-ID').format(d?.current_price)}</div>
                                            <div className='flex justify-end items-center pr-3'>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-between p-3 font-semibold'>
                                <div className=''>Total Belanjaan</div>
                                <div>Rp. {Intl.NumberFormat('en-ID').format(preview?.transaction_amount)}</div>

                            </div>
                        </div>
                    </div>

                    <div className='border border-solid p-4 rounded-xl mb-3'>
                        <div className='h1 font-bold text-slate-600 mb-2'>VOUCHER</div>

                        <div className='flex w-full'>
                            <div className='basis-1/4 flex items-center justify-center'>
                                <img className=" max-h-12" src={preview?.voucher_loyalty?.voucher?.voucher_image} alt="" />
                            </div>
                            <div className='basis-3/4 flex justify-between items-center text-sm '>
                                <div >{preview?.voucher_loyalty?.voucher?.name}</div>
                                <div className='text-red-600'>(Rp. {Intl.NumberFormat('en-ID').format(preview?.transaction_discount)})</div>
                            </div>
                        </div>
                    </div>
                    {preview?.shipping_destination_address ? (
                        <div className='border border-solid p-4 rounded-xl mb-3'>
                            <div className='h1 font-bold text-slate-600'>ALAMAT PENGIRIMAN</div>
                            <div className='p-4 text-sm'>
                                <div dangerouslySetInnerHTML={{ __html: preview?.shipping_destination_address }} />
                            </div>
                        </div>

                    ) : ('')}

                    {preview?.shipping_service_detail ? (
                        <div className='border border-solid p-4 rounded-xl mb-3'>
                            <div className='h1 font-bold text-slate-600'>JASA PENGIRIMAN</div>
                            <div className='flex justify-between mt-2 text-sm'>
                                <div>{preview?.shipping_service_detail?.expedition?.name} ({preview?.shipping_service_detail?.expedition?.service?.cost?.etd} hari)</div>
                                <div>Rp {Intl.NumberFormat('en-ID').format(preview?.shipping_service_detail?.expedition?.service?.cost?.value)}</div>
                            </div>
                        </div>
                    ) : ("")}

                    <div className='border border-solid p-4 rounded-xl mb-3'>
                        
                        <div className='flex justify-between mt-2 text-sm'>
                            <div className='h1 font-bold text-slate-600'>TOTAL YANG HARUS DIBAYAR</div>
                            <div className='text-md font-bold'>Rp {Intl.NumberFormat('en-ID').format(preview?.transaction_total_amount)}</div>
                        </div>
                    </div>

                    <div>
                        FAQ
                    </div>

                    <div>
                        <button className='btn w-full hover:opacity-50 text-white' style={{ backgroundColor: `${primaryColor}` }} onClick={() =>handlePay()}>BAYAR</button>
                    </div>


                </div>

            </form>

            {token?(
					<MidT 
						clientKey={process.env.REACT_APP_CLIENT_KEY_MIDTRANS} 
						token={token}
						onSuccess={(result)=>handlePaySuccess(result)}
						onError={(result)=>handlePayError(result)}
						onPending={(result)=>handlePayPending(result)}
						onClose={()=>handlePayClose()}


					/>
				):(``)} 

        </div>
    )
}

export default PreOrder