import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button, Pagination, Card } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'
import DefaultLogo from "../../assets/default-logo.png"
import DefaultShop from "../../assets/coffee-shop.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { Link, useParams } from 'react-router-dom'
import { BiSolidObjectsVerticalCenter } from 'react-icons/bi'
import { AiOutlineDelete } from "react-icons/ai";
import ShoppingSummary from '../../components/ShoppingSummary'
import DefaultProduct from '../../assets/default_product.png'
import { auth, session } from '../../utils/AuthSession'
import { ThemeContext } from '../../context/ThemeContext'
function Cart() {
    const [Auth,setAuth] = useState()
    const [carts,setCarts] = useState([])
    const [data, setData] = useState([])
    const [page, setPage] = useState({
        total: 1,
        perPage: 15,
        page: 1,
        lastPage: 1
    })

    const [total, setTotal] = useState(0)
    const [jml, setJml] = useState(0)

    const {primaryColor,primaryColorHover} = useContext(ThemeContext) 
    const history = useNavigate()

    // console.log('data',data)
    useEffect(()=>{
        auth().then((d)=>{
            setAuth(d)
        }) 
    },[])

    useEffect(()=>{
        FnCart(Auth?.data?.member_id,Auth?.data?.partner_id)
    },[Auth])

    
    useEffect(() => {
        let t = 0
        let count = 0
        for (const d of data) {
            if (d.checked === '1') {
                let st = d.current_price * d.quantity
                t = t + st;
                count++
            }
        }
        setTotal(t)
        setJml(count)
    }, [data])

    const FnCart = async (member_id,partner_id) => {
        const params = {
            access: 'auth',
                method: API.CART.type,
                url: API.CART.url,
                reqBody: {
                    page: 1,
                    rows: 1000,
                    filter: [
                      {
                        field: "member_id",
                        operator: "=",
                        value: member_id
                      },
                      {
                        field: "partner_id",
                        operator: "=",
                        value: partner_id
                      },
                    ]
                }
        }
        try {
            const response = await Api(params)
            setData(response.data.data)
            
        } catch (error) {
            
        }
    }

    const init = async () => {
        const params = {
            access: 'auth',
            method: API.CART.type,
            url: API.CART.url,
            reqBody: {
                page: page.page,
                rows: page.perPage,
                

            }
        }
        try {
            const response = await Api(params);
            if (response.status) {
                // console.log('data', response.data.data)

                setData(response.data.data)
                setPage({
                    total: response.data.total,
                    perPage: response.data.perPage,
                    page: response.data.page,
                    lastPage: response.data.lastPage
                })
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }


    }

    const updateCart = async (d) => {
        const params = {
            access: 'auth',
            method: API.EDIT_CART.type,
            url: API.EDIT_CART.url,
            reqBody: {
                cart_id: d.cart_id,
                item_id: d.item_id,
                quantity: d.quantity
            }
        }
        try {
            const response = await Api(params)
            if (!response.status) {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }

    }

    const deleteCart = async (d) => {
        const params = {
            access: 'auth',
            method: API.DELETE_CART.type,
            url: API.DELETE_CART.url,
            reqBody: {
                cart_id: d.cart_id,
            }
        }
        try {
            const response = await Api(params)
            if (!response.status) {
                toast.error(response.message)
            } else {
                init()
            }
        } catch (error) {
            toast.error(error.message)
        }

    }

    const handleQty = async (d, i) => {
        // console.log(i)
        let copyDataStr = JSON.stringify(data)
        let copyData = JSON.parse(copyDataStr)


        if (d.quantity < d.menu_current_quantity) {
            copyData[i].quantity = copyData[i].quantity + 1
        } else {
            copyData[i].quantity = d.menu_current_quantity
        }
        setData(copyData)
        updateCart(copyData[i])
    }

    const handleOnChangeQty = async (e, d, i) => {

        // console.log('d',d)
        let copyDataStr = JSON.stringify(data)
        let copyData = JSON.parse(copyDataStr)


        if (e.target.value > d.menu_current_quantity) {
            copyData[i].quantity = d.menu_current_quantity
        } else if (e.target.value < 0) {
            // console.log('event',e.target.value)
            copyData[i].quantity = 0
        } else {
            copyData[i].quantity = e.target.value
        }
        setData(copyData)
        updateCart(copyData[i])

    }

    const handleChecked = async (e, d, i) => {
        // console.log('event',e)
        // console.log('data',d)
        // console.log('index',i)
        let copyDataStr = JSON.stringify(data)
        let copyData = JSON.parse(copyDataStr)

        const params = {
            access: 'auth',
            method: API.EDIT_CART.type,
            url: API.EDIT_CART.url,
            reqBody: {
                cart_id: d.cart_id,
                checked: d.checked === '0' ? '1' : '0'
            }
        }
        try {
            const response = await Api(params)
            if (!response.status) {
                toast.error(response.message)
            } else {
                init()
            }
        } catch (error) {
            toast.error(error.message)
        }

    }

    const handleBuyClick = async () =>{
        history(`/order`)
    }

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <div className="h-screen container mx-auto p-1 pt-20 pb-24  overflow-auto no-scrollbar">
                {/* <div className='h1 text-center font-bold text-slate-600'>Keranjang</div> */}
                {/* <div className='flex justify-between'>
                    <div className="form-control">
                        <label className="cursor-pointer label">
                            <input type="checkbox" className="checkbox checkbox-error" />
                            <span className="label-text pl-3">Pilih semua</span>
                        </label>
                    </div>
                    <button className='btn'>Hapus</button>
                </div> */}

                <div className='flex gap-3'>
                    <div className='basis-full md:basis-2/3 '>
                        {data?.map((d, i) => (
                            <div key={i} className='flex flex-row form-control rounded-xl border border-solid border-slate-200 m-2 bg-white shadow-md'>
                                <label className="cursor-pointer label basis-1/4">
                                    <input type="checkbox" className="checkbox checkbox-error" checked={d?.checked === '1' ? true : false} onChange={(e) => handleChecked(e, d, i)} />
                                    <div>
                                        <img src={d?.item_image} alt="" />
                                    </div>
                                </label>
                                <div className='basis-3/4 pl-3'>
                                    <div className='text-sm text-red-500'>sisa : {d?.menu_current_quantity}</div>
                                    <div className='whitespace-nowrap overflow-hidden text-ellipsis'>{d?.item_name}</div>
                                    <div className='font-bold'>Rp. {Intl.NumberFormat('en-ID').format(d?.current_price)}</div>
                                    <div className='flex justify-end items-center pr-3'>
                                        <div className='flex'>
                                            <button className='btn border-none rounded-tr-none rounded-br-none p-1 min-h-[2rem] h-[2rem]' style={{backgroundColor:`${primaryColor}`}} onClick={() => deleteCart(d)}><AiOutlineDelete /></button>
                                            <input type="number" className='input rounded-none p-1 w-10 text-sm h-[2rem]' value={data[i].quantity} onChange={(event) => handleOnChangeQty(event, d, i)} />
                                            <button className='btn border-none rounded-tl-none rounded-bl-none p-1 min-h-[2rem] h-[2rem]' style={{backgroundColor:`${primaryColor}`}} onClick={() => handleQty(d, i)}>+</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className='basis-1 md:basis-1/3'>
                        <ShoppingSummary total={total} count={jml} onClick={()=>handleBuyClick()}/>
                    </div>
                </div>
                
            </div>

        </div>
    )
}

export default Cart