
import React, { useState, useEffect, useContext } from 'react'
import { auth, session, store } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useForm, SubmitHandler } from "react-hook-form"
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-mobile-datepicker';
import { ThemeContext } from '../../context/ThemeContext';
const CryptoJS = require("crypto-js");

const Order = () => {
    const [Auth, setAuth] = useState()
    const [carts, setCarts] = useState([])
    const [address, setAddress] = useState([])
    const [selectedAdderss, setSelectedAddress] = useState()
    const [total, setTotal] = useState(0)
    const [shipping, setShipping] = useState([])
    const [selectedShipping, setSelectedShipping] = useState()
    const [partner_id, setPartnerID] = useState()
    const [Store, setStore] = useState()
    const [ms_payment, setMsPayment] = useState([])
    const [store_payment, setStorePayment] = useState([])
    const [optionPayment, setOptionPayment] = useState([])
    const [selectedPayment, setSelectedPayment] = useState()
    const [my_vouchers, setMyVouchers] = useState([])
    const [selectedVoucher, setSelectedVoucher] = useState()
    const [delivery, setDelivery] = useState([])
    const [selectedDelivery, setSelectedDelivery] = useState()

    const { primaryColor, primaryColorHover } = useContext(ThemeContext)

    const history = useNavigate()
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data) => {
        // console.log(data)
    }

    const FnDelivery = async () => {
        const params = {
            access: 'auth',
            url: API.DELIVERY.url,
            method: API.DELIVERY.type,
            reqBody: {}
        }
        try {
            const response = await Api(params)
            if (response.status) {
                // console.log('Delivery',response.data)
                setDelivery(response.data.map((x) => {
                    return {
                        value: x.ms_delivery_id,
                        label: x.ms_delivery_name,
                        ms_delivery_id: x.ms_delivery_id,
                        ms_delivery_name: x.ms_delivery_name,
                        ms_delivery_identifier: x.ms_delivery_identifier
                    }
                }))
                setSelectedDelivery({
                    value: response.data[0].ms_delivery_id,
                    label: response.data[0].ms_delivery_name,
                    ms_delivery_id: response.data[0].ms_delivery_id,
                    ms_delivery_name: response.data[0].ms_delivery_name,
                    ms_delivery_identifier: response.data[0].ms_delivery_identifier
                })
            }


        } catch (error) {
            toast.error(error.message)
        }
    }
    const FnMyVoucher = async () => {
        const params = {
            access: 'auth',
            url: API.REDEEM_VOUCHER.url,
            method: API.REDEEM_VOUCHER.type,
            reqBody: {
                page: 1,
                rows: 1000,
                filter: [
                    {
                        field: "used",
                        operator: "=",
                        value: "0"
                    },
                    {
                        field: "expire_date",
                        operator: ">=",
                        value: moment().format('YYYY-MM-DD HH:mm:ss')
                    }
                ]
            }
        }
        try {
            const response = await Api(params)
            if (response.status) {
                console.log('myVoucher', response.data.data)
                setMyVouchers(response.data.data)
            }


        } catch (error) {
            toast.error(error.message)
        }
    }

    const FnMSPayment = async () => {
        const params = {
            access: 'auth',
            url: API.MS_PAYMENT.url,
            method: API.MS_PAYMENT.type,
            reqBody: {}
        }
        try {
            const response = await Api(params)
            if (response.status) {
                console.log('PatmentMethod', response.data)
                setMsPayment(response.data)
                setSelectedPayment(response.data[0])

            } else {
                // toast.error(response.message)
            }

        } catch (error) {
            toast.error(error.message)
        }
    }
    const FnShipping = async (store_id, destination) => {
        const params = {
            access: 'auth',
            url: API.SHIPPING.url,
            method: API.SHIPPING.type,
            reqBody: {
                store_id: store_id,
                destination: destination
            }
        }
        try {
            const response = await Api(params)
            if (response.status) {
                // console.log('shipping', response.data)
                setShipping(response.data)

            } else {
                // toast.error(response.message)
            }

        } catch (error) {
            toast.error(error.message)
        }
    }

    const FnAddress = async () => {
        const params = {
            access: 'auth',
            method: API.ADDRESS.type,
            url: API.ADDRESS.url,
            reqBody: {}
        }
        try {
            const response = await Api(params)
            if (response.status) {
                setAddress(response.data)
                setSelectedAddress(response.data.find(e => e.address_default === '1'))

            } else {
                toast.error(response.message)
            }

        } catch (error) {
            toast.error(error.message)
        }
    }

    // console.log('Address', address)
    // console.log('selectedAddress', selectedAdderss)
    const FnPrice = () => {

    }

    const FnCart = async (member_id, partner_id) => {
        const params = {
            access: 'auth',
            method: API.CART.type,
            url: API.CART.url,
            reqBody: {
                page: 1,
                rows: 1000,
                filter: [
                    {
                        field: "member_id",
                        operator: "=",
                        value: member_id
                    },
                    {
                        field: "partner_id",
                        operator: "=",
                        value: partner_id
                    },
                    {
                        field: "checked",
                        operator: "=",
                        value: "1"
                    },

                ]
            }
        }
        try {
            const response = await Api(params)
            // console.log('response', response)
            setCarts(response.data.data)

        } catch (error) {

        }
    }
    useEffect(() => {
        auth().then((data) => {
            setAuth(data)
        })
        store().then((data) => {
            setStore(data)
            setStorePayment(data.data.data.store_payment_method_information.map(x => {
                return {
                    value: x.ms_payment_id,
                    label: x.ms_payment_name,
                    ms_payment_identifier: x.ms_payment_identifier
                }
            }))
            setSelectedPayment(data.data.data.store_payment_method_information[0])
        })
        FnDelivery()
        FnAddress()
        FnMSPayment()
        FnMyVoucher()
    }, [])
    useEffect(() => {
        FnCart(Auth?.data?.member_id, Auth?.data?.partner_id)

    }, [Auth])
    useEffect(() => {
        let t = 0
        let count = 0
        for (const d of carts) {
            if (d.checked === '1') {
                let st = d.current_price * d.quantity
                t = t + st;
                count++
            }
        }
        setTotal(t)
    }, [carts])


    useEffect(() => {
        FnShipping(Store?.data.data.store_id, selectedAdderss?.city_id)
        // console.log('selectedAdderss',selectedAdderss)
    }, [Store, selectedAdderss])

    useEffect(() => {
        // console.log('shipping', shipping)
        if (shipping?.costs?.length > 0) {
            // console.log('SelectedShipping', shipping?.costs[shipping?.costs?.length - 1])
            setSelectedShipping(shipping?.costs[shipping?.costs?.length - 1])
        }
    }, [shipping])

    useEffect(() => {
        // console.log('selectedShipping', selectedShipping)
    }, [selectedShipping])

    useEffect(() => {
        // console.log('Store', Store)
    }, [Store])



    useEffect(() => {
        // console.log('store_payment', store_payment)
    }, [store_payment])

    // console.log('store',Store)
    // console.log('partner_id',partner_id)
    // console.log('selectedAddress', selectedAdderss)
    // console.log('Shipping', shipping)
    // console.log('selectedShipping', selectedShipping)
    const handlePaymentMethodChange = async (e) => {
        // console.log(e)
    }
    const handleVoucherBtnClick = async (e) => {
        document.getElementById('select_voucher_modal').showModal()
    }

    const handleSelectVoucher = async (e) => {
        setSelectedVoucher(e)
        console.log('selectedVoucher', e)
        document.getElementById("select_voucher_modal").close()
    }

    const handleSelectAddress = async (e) => {
        setSelectedAddress(e)
        document.getElementById("select_address_modal").close()
    }
    const handleSelectShipping = async (e) => {
        setSelectedShipping(e)
        document.getElementById("select_shipping_modal").close()
    }
    const Calculate = async () => {
        // const code = CryptoJS.AES.encrypt(selectedVoucher.member_voucher_id, selectedVoucher.voucher.partner.server_id).toString()
        let params = {
            item: carts.map((c) => {
                return {
                    item_id: c.item_id,
                    item_name: c.item_name,
                    quantity: c.quantity,
                    note: c.note
                }
            }),
            store_id: Store.data.data.store_id,

            ms_payment_id: Store.data.data.store_payment_method[0],
            ms_delivery_id: selectedDelivery.ms_delivery_id,
            preview_fee: true,
            customer_name: `${session().user.firstname} ${session().user.firstname}`,
            customer_msisdn: `${session().user.phone}`,
            customer_email: `${session().user.email}`,
            voucher_type: "loyalty",

        }
        console.log('selectedVoucher', selectedVoucher)
        if (selectedVoucher) {
            params.voucher_code = CryptoJS.AES.encrypt(selectedVoucher.member_voucher_id.toString(), selectedVoucher.voucher.partner.server_id).toString()
        }

        if (selectedDelivery.ms_delivery_identifier !== 'PICKUP') {
            params.shipping_destination = selectedAdderss.city_id
            params.shipping_destination_address = `${selectedAdderss.full_address}, ${selectedAdderss.city_name} - ${selectedAdderss.province_name} ${selectedAdderss.postal_code}`
            params.shipping_service = selectedShipping.service

        } else {
            params.pickup_date = moment().format("YYYY-MM-DD HH:mm:00")
        }

        const encrypt_order = CryptoJS.AES.encrypt(JSON.stringify(params), process.env.REACT_APP_SECRET_CODE).toString()
        const safeEncrypted = encrypt_order.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

        console.log(params)
        history(`/order/preorder?o=${safeEncrypted}`)
    }

    console.log('Store', Store)

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="h-screen container mx-auto p-5 pt-20 pb-24  overflow-auto no-scrollbar">
                    <div className='border border-solid p-4 rounded-xl mb-3'>

                        <div className='h1 font-bold text-slate-600'>PESANAN</div>
                        <div className='flex flex-col gap-0'>
                            <div className='basis-full md:basis-1/2 '>
                                {carts?.map((d, i) => (
                                    <div key={i} className='flex flex-row form-control border-b border-b-solid border-b-slate-200 mt-'>
                                        <div className="cursor-pointer label basis-1/12">
                                            <img src={d?.item_image} alt="" />
                                        </div>
                                        <div className='basis-3/4 pl-3'>

                                            <div className='w-[280px] whitespace-nowrap overflow-hidden text-ellipsis'>{d?.item_name}</div>
                                            <div className='font-bold text-sm'>{d?.quantity} x Rp. {Intl.NumberFormat('en-ID').format(d?.current_price)}</div>
                                            <div className='flex justify-end items-center pr-3'>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-between p-3 font-semibold'>
                                <div className=''>Total Belanjaan</div>
                                <div>Rp. {Intl.NumberFormat('en-ID').format(total)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='border border-solid p-4 rounded-xl mb-3'>
                        <div className='h1 font-bold text-slate-600 mb-2'>VOUCHER</div>
                        {selectedVoucher ? (
                            <div className='flex w-full'>
                                <div className='basis-1/4 flex items-center justify-center'>
                                    <img className=" max-h-12" src={selectedVoucher.voucher.voucher_image} alt="" />
                                </div>
                                <div className='basis-3/4 flex justify-between items-center text-sm '>
                                    <div >{selectedVoucher.voucher.name}</div>
                                    <div className='text-red-600'>(Rp. {Intl.NumberFormat('en-ID').format(selectedVoucher.amount)})</div>
                                </div>

                            </div>
                        ) : (
                            <div className='italic'>Tidak ada voucher yang dipasang</div>
                        )}
                        <div><button className='btn btn-sm w-full mt-2 hover:opacity-50 text-white' style={{ backgroundColor: `${primaryColor}` }} onClick={handleVoucherBtnClick}>Pilih Voucher</button></div>
                    </div>

                    <div className='border border-solid p-4 rounded-xl mb-3'>
                        <div className='h1 font-bold text-slate-600'>METODA PENGIRIMAN</div>
                        <Select options={delivery} value={selectedDelivery} onChange={(e) => setSelectedDelivery(e)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'grey' : primaryColor,
                                }),
                                option: (baseStyles,state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isSelected ? primaryColor : 'white'
                                })
                            }}
                        />
                    </div>



                    {selectedDelivery?.ms_delivery_identifier === 'PICKUP' ? (
                        <div className='border border-solid p-4 rounded-xl mb-3 text-sm'>
                            <div className='h1 font-bold text-slate-600'>PENGAMBILAN</div>
                            <div>Anda bisa mengambil pesanan anda di:</div>
                            <div className='font-semibold'>{Store?.data?.data?.store_name}</div>
                            <div>{Store?.data?.data?.store_address}</div>
                            <div>Jam operasional : {Store?.data?.data?.store_operation_time}</div>
                        </div>
                    ) : (
                        <div>
                            <div className='border border-solid p-4 rounded-xl mb-3'>
                                <div className='h1 font-bold text-slate-600'>ALAMAT PENGIRIMAN</div>
                                <div className='p-4 text-sm'>
                                    <div className='font-semibold'>{selectedAdderss?.address_name}</div>
                                    <div dangerouslySetInnerHTML={{ __html: selectedAdderss?.full_address }} />
                                    <div>{selectedAdderss?.city_name} - {selectedAdderss?.province_name} {selectedAdderss?.postal_code}</div>
                                </div>
                                <button className='btn btn-sm mt-2 w-full hover:opacity-50 text-white' style={{ backgroundColor: `${primaryColor}` }} onClick={() => document.getElementById('select_address_modal').showModal()}>Ganti Alamat</button>
                            </div>
                            <div className='border border-solid p-4 rounded-xl mb-3'>
                                <div className='h1 font-bold text-slate-600'>JASA PENGIRIMAN</div>
                                <div className='flex justify-between mt-2 text-sm'>
                                    <div>{selectedShipping?.description} ({selectedShipping?.cost[0]?.etd} hari)</div>
                                    <div>Rp {Intl.NumberFormat('en-ID').format(selectedShipping?.cost[0]?.value)}</div>
                                </div>
                                <button className='btn w-full btn-sm mt-2 hover:opacity-50 text-white' style={{ backgroundColor: `${primaryColor}` }} onClick={() => document.getElementById('select_shipping_modal').showModal()}>Ganti Kurir</button>
                            </div>
                        </div>
                    )}




                    {/* <div>
                        <div className='h1 text-center font-bold text-slate-600'>Metode Pembayaran</div>
                        <div>
                            <Select options={store_payment} onChange={handlePaymentMethodChange}/>
                        </div>
                    </div> */}

                    <div>
                        <button className='btn w-full hover:opacity-50 text-white' style={{ backgroundColor: `${primaryColor}` }} onClick={() => Calculate()}>Tinjau Pesanan</button>
                    </div>

                </div>
            </form>
            <dialog id="select_voucher_modal" className="modal">
                <div className="modal-box w-full">
                    <h3 className="font-bold text-lg mb-4">Voucher anda</h3>
                    <div>
                        {my_vouchers.map((v, i) => (
                            <div key={i} className='flex hover:cursor-pointer' onClick={() => handleSelectVoucher(v)}>
                                <div className='flex basis-1/4 mt-5 items-center justify-center'>
                                    <img className="max-h-14" src={v.voucher.voucher_image} alt="" />
                                </div>
                                <div className='basis-3/4'>
                                    <div>{v.voucher.name}</div>
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className='flex justify-end'>
                        <button className='btn' onClick={() => document.getElementById('select_voucher_modal').close()}>Batal</button>
                    </div>
                </div>
            </dialog>
            <dialog id="select_address_modal" className="modal">
                <div className="modal-box w-full">
                    <h3 className="font-bold text-lg mb-4">Pilih Alamat</h3>
                    <div>
                        {address.map((a, i) => (
                            <div key={i} className='flex flex-col hover:cursor-pointer hover:bg-slate-400 p-2 border rounded-xl' onClick={() => handleSelectAddress(a)}>
                                <div>{a.address_name}</div>
                                <div dangerouslySetInnerHTML={{ __html: a.full_address }} />
                                <div>{a.city_name} - {a.province_name}</div>
                                <div>{a.postal_code}</div>
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-end'>
                        <button className='btn' onClick={() => document.getElementById('select_address_modal').close()}>Batal</button>
                    </div>
                </div>
            </dialog>
            <dialog id="select_shipping_modal" className="modal">
                <div className="modal-box w-full">
                    <h3 className="font-bold text-lg mb-4">Pilih Jasa Pengiriman</h3>
                    <div className='text-sm'>
                        {shipping?.costs?.map((s, i) => (
                            <div key={i} className='flex justify-between items-center hover:cursor-pointer min-h-10 border rounded-md p-5 mb-2 hover:bg-slate-300' onClick={() => handleSelectShipping(s)}>
                                <div>{s?.description} ({s?.cost[0]?.etd} hari)</div>
                                <div>{Intl.NumberFormat('en-ID').format(s?.cost[0]?.value)}</div>
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-end'>
                        <button className='btn' onClick={() => document.getElementById('select_shipping_modal').close()}>Batal</button>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default Order