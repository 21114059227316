import React, { useState, useEffect, useContext } from 'react'
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler } from "react-hook-form"
import { auth, session, store } from '../../utils/AuthSession'
import { Helmet } from 'react-helmet';
import MidT from '../../components/MidT'
import { ThemeContext } from '../../context/ThemeContext';

function TransactionList() {
    const [page,setPage] = useState(1)
    const [rows,setRows] = useState(10)
    const [transaction,setTransaction] = useState([])
    const [lastPage,setlastPage] = useState()
    const [total,setTotal] = useState()
    const [selectedTransaction, setSelectedTransaction] = useState()
    const [token,setToken] = useState()

    const {primaryColor} = useContext(ThemeContext)
    const history = useNavigate()
    
    useEffect(()=>{
        Init()
    },[])
    const Init = async () => {
        const params = {
            access: 'auth',
            url: API.TRANSACTION_LIST.url,
            method: API.TRANSACTION_LIST.type,
            reqBody: {
                page: page,
                rows: rows,
                order_by: "transaction_created_datetime",
                sort_by: "desc"
            }
        }
        try {
            const response = await Api(params)
            console.log('response', response)
            if (response.status) {
                console.log('Preview', response.data.data)
                setTransaction(response.data.data)
                setPage(response.data.page)
                setRows(response.data.per_page)
                setlastPage(response.data.last_page)
                setTotal(response.data.total)
            }else{
                toast.error(response.message)
            }
        } catch (error) {
        }
    }
    console.log(transaction)
    const handleResume = async (data) => {
        const params = {
            access: 'auth',
            url: API.TRANSACTION_RESUME.url,
            method: API.TRANSACTION_RESUME.type,
            reqBody: {
                transaction_id: data.transaction_id
            }
        }
        try {
            const response = await Api(params)
            console.log(response)
            if(response.status){
                setToken(response.data.token)
            }
        } catch (error) {
            
        }

    }

    const handlePaySuccess = (result) => {
        history(`/order/status/success`)
    }

    const handlePayError = (result) => {
        history(`/order/status/error`)
    }

    const handlePayPending = (result) => {
        history(`/order/status/pending`)
    }

    const handlePayClose = (result) => {
        setToken(null)
        history(`/order/status/nopay`)
    }

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <Helmet>
                <script src={process.env.REACT_APP_MIDTRANS_SNAP_SCRIPT} async></script>
            </Helmet>
            <div className="h-screen container mx-auto p-5 pt-20 pb-24  overflow-auto no-scrollbar">
                <div className='h1 font-bold text-slate-600'>DAFTAR TRANSAKSI ANDA</div>
                {transaction.map((t,i)=>(
                    <div key={i} className='border border-solid p-4 rounded-xl mb-3 bg-white shadow-md'>
                        <div className='flex text-sm justify-between'>
                            <div>{moment(t.transaction_created_datetime).format('DD MMM YYYY')}</div>
                            <div className={`badge ${t.transaction_approve_status_name==='pending'?'bg-orange-500':t.transaction_approve_status_name==='rejected'?'bg-red-700':t.transaction_approve_status_name==='approved'?'bg-green-700':'bg-slate-600'} text-white`}>{t.transaction_approve_status_name}</div>
                        </div>
                        <div className='flex p-1'>
                            <div className='basis-1/6'>
                                <img src={t.transaction_detail[0].transaction_detail_payload.item_image[0]} alt="" />
                            </div>
                            <div className='p-2'>
                                <div className='text-xs'>{t.transaction_number}</div>
                                <div className='flex'>
                                    {t.transaction_detail.map((d,o)=>(
                                        <div key={o} className='text-sm'>{o==0?d.transaction_detail_item_name:' - '+d.transaction_detail_item_name}</div>
                                    ))}
                                </div>
                                <div>
                                    Rp {Intl.NumberFormat('en-ID').format(t.transaction_total_amount)}
                                </div>
                            </div>
                            
                        </div>
                        <div>
                            {t.transaction_approve_status_name ==='pending'?(
                                <button className='btn hover:opacity-50 text-white' style={{backgroundColor: `${primaryColor}`}} onClick={()=>handleResume(t)}>resume</button>
                            ):('')}
                            
                        </div>
                    </div>
                ))}
                <div className='flex justify-between'>
                        <button className='btn' disabled={page === 1 ? true : false} onClick={()=>{}}>prev</button>
                        <button className='btn' disabled={page === lastPage ? true : false} onClick={()=>{}}>next</button>
                    </div>
                
                    
                    
                
            </div>
            {token?(
					<MidT 
						clientKey={process.env.REACT_APP_CLIENT_KEY_MIDTRANS} 
						token={token}
						onSuccess={(result)=>handlePaySuccess(result)}
						onError={(result)=>handlePayError(result)}
						onPending={(result)=>handlePayPending(result)}
						onClose={()=>handlePayClose()}


					/>
				):(``)} 
        </div>
    )
}

export default TransactionList