export const API = {
	REQUEST_TOKEN: {
		url: 'member/auth/request/token',
		type: 'post',
	},
	LOGIN_TOKEN: {
		url: 'member/auth/login/token',
		type: 'post'
	},
	PROFILE: {
		url: 'member/profile',
		type: 'get'
	},
	POINT: {
		url: 'member/points',
		type: 'get'
	},

	VOUCHER: {
		url: 'member/vouchers',
		type: 'get'
	},

	REDEEM_VOUCHER: {
		url: "member/redeem/voucher",
		type: "get"
	},
	REDEEM: {
		url: "member/redeem",
		type: "post"
	},
	MERCHANT: {
		url: "member/partner",
		type: "get"
	},
	MERCHANT_DETAIL: {
		url: "member/partner/detail",
		type: "get"
	},
	REGISTER: {
		url: "merchant/member/register",
		type: "post"
	},
	PRODUCT: {
		url: "member/product",
		type: 'get'
	},
	PRODUCT_GET: {
		url: "member/product/get",
		type: 'get'
	},
	STORE: {
		url: "member/store",
		type: 'get'
	},
	STORE_GET: {
		url: "member/store/get",
		type: 'get'
	},

	ADD_CART: {
		url: "member/cart",
		type: 'post'
	},
	CART: {
		url: "member/cart",
		type: 'get'
	},
	EDIT_CART: {
		url: "member/cart",
		type: 'put'
	},
	DELETE_CART: {
		url: "member/cart",
		type: 'delete'
	},

	ADDRESS: {
		url: "member/address",
		type: 'get'
	},
	ADDRESS_GET: {
		url: "member/address/get",
		type: 'get'
	},

	ADDRESS_CREATE: {
		url: "member/address",
		type: 'post'
	},
	ADDRESS_EDIT: {
		url: "member/address",
		type: 'put'
	},
	ADDRESS_DELETE: {
		url: "member/address",
		type: 'delete'
	},
	PROVINCE: {
		url: "member/master/province",
		type: "get"
	},
	CITY: {
		url: "member/master/city",
		type: "get"
	},
	AUTH: {
		url: "member/auth",
		type: "get"
	},
	SHIPPING: {
		url: "member/shipping/cost",
		type: "get"
	},
	MS_PAYMENT: {
		url: "member/master/payment",
		type: "get"
	},
	DELIVERY: {
		url: "member/master/delivery",
		type: "get"
	},
	TRANSACTION: {
		url: "member/transaction",
		type: "post"
	},
	TRANSACTION_LIST: {
		url: "member/transaction",
		type: "get"
	},
	TRANSACTION_RESUME: {
		url: "member/transaction/resume",
		type: "put"
	},



}