import axios from 'axios';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { session } from '../utils/AuthSession';
var qs = require('qs');


export const Api = async (params,pub=true) => {

    let Token, EncryptedCookie, response
	
	const config = {
		baseURL: (pub)?(process.env.REACT_APP_CORE_LOYALTY_SYSTEM):(process.env.REACT_APP_BASE_URL),
		timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
	}
    // return session().token.token
	if(params.access==='auth'){
		config.headers = {'Authorization': `${session().type} ${session().token}`}
	}

	try {
		switch (params.method) {
			case 'get':
				response =  await axios.get(params.url+'?'+qs.stringify(params.reqBody),config)
				break;
			case 'post':
				response =  await axios.post(params.url,params.reqBody,config)
				break;
			case 'put':
				config.data = params.reqBody;
				response =  await axios.put(params.url,params.reqBody,config)
				break;
			case 'delete':
				config.data = params.reqBody;
				response =  await axios.delete(config.baseURL+params.url,config)
				break;
			default:
				break;
		}

		return response.data

	} catch (error) {
		// alert(error)
		if(!error.response){
			return error
		}else{
			if(error.response.status === 401 || error.response.status === 403){
			}
		}
	}
}
