import React, { useState, useEffect, useRef } from 'react'
import { Avatar, Button } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session, clearSession } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import CategoryNav from '../../components/CategoryNav'

function Product() {
    const [authSession, setAuthSession] = useState(session())
    const [category, setCategory] = useState([])
    const [categorySelected, setCategorySelected] = useState()
    const [stores, setStores] = useState([])
    const [store, setStore] = useState([])
    const [store_slug, setStoreSlug] = useState()
    const [productCategory, setProductCategory] = useState([])
    const [products, setProducts] = useState([])

    const history = useNavigate()

    const ref = useRef()

    const initStore = async () => {
        const params = {
            access: 'auth',
            method: API.STORE.type,
            url: API.STORE.url,
            reqBody: {
                partner_id: session().partner_id
            }
        }
        const response = await Api(params);
        if (response.status) {
            setStores(response.data)
            if (response.data.length > 0) {
                // console.log('store', response.data)
                setStore(response.data[0])
                setStoreSlug(response.data[0].store_slug)
            }
        }
    }
    const InitProduct = async () => {
        const params = {
            access: 'auth',
            method: API.PRODUCT.type,
            url: API.PRODUCT.url,
            reqBody: {
                store_slug: store_slug
            }
        }

        const response = await Api(params);
        // console.log(response)
        if (response.status) {

            setCategory(Object.keys(response.data.menu))
            setCategorySelected(Object.keys(response.data.menu) ? Object.keys(response.data.menu)[0] : '')
            // console.log('cat',Object.keys(response.data.menu)?Object.keys(response.data.menu)[0]:'')
            setProductCategory(response.data.menu)
        }
    }

    useEffect(() => {
        initStore()
    }, [])

    useEffect(() => {
        InitProduct()
    }, [store_slug])

    useEffect(()=>{
        // setProducts(categorySelected)
        // console.log('categorySelected',categorySelected)
        if(categorySelected) {
            setProducts(productCategory[categorySelected])
        }
    },[categorySelected])

    const handleSelectChange = (e) => {
        setStoreSlug(e.target.value)
    }
    const handleOnClickCategoryNav = (e) => {
        setCategorySelected(e)
        setProducts(productCategory[e])
        // console.log('products', productCategory[e])


    }

    const handleProductClick = (e) => {
        // console.log(e)
        history(`/product/${e}`)
    }

    // console.log('product',products)

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <div className="h-screen container mx-auto p-5 pt-8 pb-24">
                <div className='text-xl font-bold text-center pt-8 pb-10'>
                    {stores.length > 1 ? (
                        <div className='bg-red-300'>
                            <select className="select w-full max-w-xs" onChange={handleSelectChange}>
                                {stores.map((s, i) => (
                                    <option key={i} value={s?.store_slug}>{s?.store_name}</option>
                                ))}
                            </select>
                        </div>
                    ) : (``)}

                    <div className=''>
                        <CategoryNav category={category} defaultCategory={categorySelected} onClick={(e) => handleOnClickCategoryNav(e)} />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-6 gap-2 pb-5 pt-5 overflow-auto no-scrollbar h-[70dvh]'>
                        {products.map((p, i) => (
                            <div key={i} className="card shadow-md h-96 hover:cursor-pointer bg-white p-1" onClick={()=>handleProductClick(p.menu_slug)}>
                                <figure>
                                    <img
                                        className='h-56'
                                        src={p.item_image}
                                        alt="Shoes" />
                                </figure>
                                <div className="card-body p-2 text-normal text-start">
                                    <div className="font-normal text-base">{p.item_name}</div>
                                    <div className="font-bold text-base">Rp. {Intl.NumberFormat('en-ID').format(p.current_price)}</div>
                                </div>
                                
                            </div>
                        ))}
                    </div>


                </div>
            </div>


        </div>
    )
}

export default Product