
import live_streaming from '../assets/live_streaming.png'
import { AiFillHome, AiOutlineHistory, AiOutlineUser, AiOutlineDollarCircle, AiOutlineGift } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import ReactLogo from '../assets/voucher.svg';
import { TiTicket } from "react-icons/ti";

export const navs = [
    {
        id: 1,
        title: "Beranda",
        link: "/",
        type: 'image',
        icon: <AiFillHome/>
    },
    // {
    //     id: 2,
    //     title: "Riwayat",
    //     link: "/history",
    //     type: 'image',
    //     icon: <AiOutlineHistory/>
    // },
    {
        id: 3,
        title: "Penukaran",
        link: "/redeem",
        type: 'image',
        icon: <AiOutlineGift/>
    },
    {
        id: 4,
        title: "Voucher",
        link: "/voucher",
        type: 'image',
        icon: <TiTicket/>
    },
    // {
    //     id: 5,
    //     title: "Merchant",
    //     link: "/merchant",
    //     type: 'image',
    //     icon: <BiStore/>
    // },
    {
        id: 5,
        title: "Profile",
        link: "/profile",
        type: 'image',
        icon: <AiOutlineUser/>
    },

    
    
]