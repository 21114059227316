import React from 'react'
import {
    BrowserRouter as Router, Routes, Route, Outlet,
} from "react-router-dom";

import Home from "../../pages/Home";
import ErrorPage from "../../pages/ErrorPage";
import Navigation from '../Navigation';
import Redeem from '../../pages/Redeem';
import Voucher from '../../pages/Voucher';
import History from '../../pages/History';
import Transaction from '../../pages/Transaction';
import Merchant from '../../pages/Merchant';
import MerchantDetail from '../../pages/MerchantDetail';
import Footer from '../Footer';
import RunningText from '../../pages/RunningText';
import MainLayout from '../../layout/MainLayout';
import FreeLayout from '../../layout/FreeLayout';
import Profile from '../../pages/Profile';
import Login from '../../pages/Login';
import Logout from '../../pages/Logout';
import Partner from '../../pages/Partner';
import Product from '../../pages/Product';
import ProductDetail from '../../pages/ProductDetail';
import Cart from '../../pages/Cart';
import Order from '../../pages/Order'
import PreOrder from '../../pages/PreOrder';
import OrderResult from '../../pages/OrderResult';
import TransactionList from '../../pages/TransactionList';

const RootRouter = () => {
    
    return (
        <>
            <Router>
                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home/>} />
                        <Route path='/history' element={<History />} />
                        <Route path='/redeem' element={<Redeem />} />
                        <Route path='/voucher' element={<Voucher />} />
                        <Route path='/transaction' element={<Transaction />} />
                        <Route path='/transaction/list' element={<TransactionList />} />
                        <Route path='/merchant' element={<Merchant />} />
                        <Route path='/product' element={<Product />} />
                        <Route path='/product/:product_slug' element={<ProductDetail />} />
                        <Route path='/cart' element={<Cart />} />
                        <Route path='/order' element={<Order />} />
                        <Route path='/order/preorder' element={<PreOrder />} />
                        <Route path='/order/status/:status' element={<OrderResult />} />

                        <Route path='/merchant/:id' element={<MerchantDetail />} />
                        <Route path='/profile' element={<Profile />} />
                    </Route>

                    <Route path='/auth' element={<FreeLayout/>}>
                        <Route path='login' element={<Login/>}/>
                        <Route path='logout' element={<Logout/>}/>
                        <Route path='partner/:cid/:sid/:lid' element={<Partner/>}/>

                    </Route>
                    
                    <Route path='*' element={<ErrorPage />} />
                    <Route path='/404' element={<ErrorPage />} />
                </Routes>
                
            </Router>

            
        </>

    )

}


export default RootRouter