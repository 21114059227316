
import live_streaming from '../assets/live_streaming.png'
import { AiOutlineShoppingCart , AiOutlineUser, AiOutlineSkin, AiOutlineGift, AiOutlineMail } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import ReactLogo from '../assets/voucher.svg';
import { TiTicket } from "react-icons/ti";

export const topLefts = [
    



    {
        id: 5,
        title: "Product",
        link: "/product",
        type: 'image',
        icon: <AiOutlineSkin />
    },
    

    
    
]