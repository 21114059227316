import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom'
import SocialButton from "../SocialButton";
import Logo from '../../assets/mic_logo.png'
import { topRights } from '../../jsons/topRights'
import { topLefts } from "../../jsons/topLefts";
import { session, clearSession, setSession, auth } from '../../utils/AuthSession'
import { gapi } from 'gapi-script';
import { GoogleLogin, GoogleLogout } from 'react-google-login'
import { AiOutlineLeft } from "react-icons/ai";
import { ThemeContext } from "../../context/ThemeContext";

const Navbar = (props) => {
    const {primaryColor,primaryColorHover} = useContext(ThemeContext)
    const navigate = useNavigate()
    const handleOnSuccess = () => {
        window.location.reload(false);
    }

    const handleLogout = () => {
        clearSession()
        setSession(session)
        window.location.reload(false);
    }


    return (
        <div className={`fixed flex z-50 w-full h-16 border border-none top-0`} style={{backgroundColor:`${primaryColor}`}}>
            <div className="container flex justify-between  mx-auto">
                <div className="flex">
                    <div className="flex grid-cols-1">
                        <button type="button" 
                            className={`inline-flex flex-col items-center justify-center px-5 hover: group text-2xl text-white`}
                            onClick={() => navigate(-1)}
                        >
                            <AiOutlineLeft />

                        </button>
                    </div>
                    <div className="flex h-full grid-cols-6">
                        {topLefts.map((nav, i) => (
                            <button key={i} type="button" className={`inline-flex flex-col items-center justify-center px-5 hover:opacity-50 group text-2xl text-white`}
                                onClick={() => navigate(nav.link)}
                            >
                                {nav.icon}
                                <span className="text-xs">{nav.title}</span>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="flex h-full grid-cols-6">
                    {topRights.map((nav, i) => (
                        <button key={i} type="button" className={`inline-flex flex-col items-center justify-center px-5 hover:opacity-50 group text-2xl text-white`}
                            onClick={() => navigate(nav.link)}
                        >
                            {nav.icon}
                            <span className="text-xs">{nav.title}</span>
                        </button>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Navbar
