import Cookie from 'js-cookie'
import { API } from '../api'
import { Api } from '../apiServer'
const CryptoJS = require("crypto-js");
export const session = () =>{
    let out = false
    var getCookie = Cookie.get(process.env.REACT_APP_AUTH_COOKIE)
    // console.log('cookie',process.env.REACT_APP_AUTH_COOKIE)
    // getCookie = CryptoJS.AES.encrypt('my message', process.env.REACT_APP_SECRET_CODE).toString();
    if (getCookie) {
        var bytes  = CryptoJS.AES.decrypt(getCookie, process.env.REACT_APP_SECRET_CODE);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        if(originalText){
            try {
                out = JSON.parse(originalText)      
            } catch (error) {
                out = false
            }
           
        }
    }
    return out
}

export function setSession(data) {
    const cookieValue = JSON.stringify(data)
    const encCookie = CryptoJS.AES.encrypt(cookieValue, process.env.REACT_APP_SECRET_CODE).toString();
    Cookie.set(process.env.REACT_APP_AUTH_COOKIE, encCookie)
}

export function clearSession(data) {
    Cookie.remove(process.env.REACT_APP_AUTH_COOKIE) 
}

export function setStore(data) {
    const cookieValue = JSON.stringify(data)
    const encCookie = CryptoJS.AES.encrypt(cookieValue, process.env.REACT_APP_SECRET_CODE ).toString();
    Cookie.set(process.env.REACT_APP_AUTH_COOKIE + '_store', encCookie)
}

export function clearStore(data) {
    Cookie.remove(process.env.REACT_APP_AUTH_COOKIE + '_store') 
}

// export const store = () =>{
//     let out = false
//     var getCookie = Cookie.get(process.env.REACT_APP_AUTH_COOKIE + '_store')
//     // console.log('cookie',process.env.REACT_APP_AUTH_COOKIE)
//     // getCookie = CryptoJS.AES.encrypt('my message', process.env.REACT_APP_SECRET_CODE).toString();
//     if (getCookie) {
//         var bytes  = CryptoJS.AES.decrypt(getCookie, process.env.REACT_APP_SECRET_CODE);
//         var originalText = bytes.toString(CryptoJS.enc.Utf8);
//         if(originalText){
//             try {
//                 out = JSON.parse(originalText)      
//             } catch (error) {
//                 out = false
//             }
//         }
//     }
//     return out
// }

export const store = () =>{
    return new Promise(async (resolve, reject) => {
        const params = {
            access: 'auth',
                method: API.STORE_GET.type,
                url: API.STORE_GET.url,
                reqBody: {}
        }
        try {
            const response = await Api(params)    
            resolve(response)
        } catch (error) {
            // console.log(error.message)
            reject({
                status: false,
                message: error.message
            }) 
        }
    })
}

export function auth() {

    return new Promise(async (resolve, reject) => {
        const params = {
            access: 'auth',
                method: API.AUTH.type,
                url: API.AUTH.url,
                reqBody: {}
        }
        try {
            const response = await Api(params)    
            resolve(response)
        } catch (error) {
            reject({
                status: false,
                message: error.message
            }) 
        }
    })
    
    
  
}

