import React, { createContext, useState, useContext, useEffect } from 'react';
import {session, auth} from '../utils/AuthSession'
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [primaryColor, setPrimaryColor] = useState();
    const [primaryColorHover, setPrimaryColorHover] = useState();
    
    useEffect(()=>{
    //    console.log('useEffect dari context')
       auth().then((data)=>{
        setPrimaryColor(data.data.partner.primary_color)
        setPrimaryColorHover(data.data.partner.primary_color_hover)
    })
    },[])

    useEffect(()=>{
        // console.log('primary.color', primaryColor)
    },[primaryColor])
    return (
        <ThemeContext.Provider value={{ primaryColor, primaryColorHover }}>
            {children}
        </ThemeContext.Provider>
    );
};
