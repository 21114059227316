
import live_streaming from '../assets/live_streaming.png'
import { AiOutlineShoppingCart , AiOutlineHistory, AiOutlineUser, AiOutlineDollarCircle, AiOutlineGift, AiOutlineMail } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import ReactLogo from '../assets/voucher.svg';
import { TiTicket } from "react-icons/ti";
import { MdListAlt } from "react-icons/md";

export const topRights = [
    



    {
        id: 5,
        title: "Transaksi",
        link: "/transaction/list",
        type: 'image',
        icon: <MdListAlt/>
    },
    {
        id: 5,
        title: "Keranjang",
        link: "/cart",
        type: 'image',
        icon: <AiOutlineShoppingCart/>
    },

    
    
]