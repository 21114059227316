import React, { useState, useEffect, useRef,useContext } from 'react'
import { Button, Pagination, Card } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'
import DefaultLogo from "../../assets/default-logo.png"
import DefaultShop from "../../assets/coffee-shop.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { Link, useParams  } from 'react-router-dom'
import { BiSolidObjectsVerticalCenter } from 'react-icons/bi'
import { ThemeContext } from '../../context/ThemeContext'

const CryptoJS = require("crypto-js");

function Voucher() {
    const [authSession, setAuthSession] = useState(session())
    const [data, setData] = useState()
    const [selectedImage, setSelectedImage] = useState()
    const [images,setImages] = useState([])
    const [qty, setQty] = useState(1)
    const [total, setTotal] = useState(0)
    const [note, setNote] = useState('')

    const {primaryColor,primaryColorHover} = useContext(ThemeContext)
    console.log(primaryColor)
    let { product_slug } = useParams();
    const history = useNavigate()
    useEffect(() => {
        init();
    }, [])
    console.log('authSession',authSession)
    useEffect(()=>{
        setTotal(data?.current_price * qty)
    },[qty,data])
    
    const init = async () => {
        const params = {
            access: 'auth',
            method: API.PRODUCT_GET.type,
            url: API.PRODUCT_GET.url,
            reqBody: {
                slug: product_slug
            }
        }
        const response = await Api(params);

        // console.log('data', response)
        if (response.status) {
            setData(response.data)
            setImages(response.data.item_image)
            setSelectedImage(response.data?.item_image[0])
        }else{
            history('/404')
        }
    }

    const handleAddOnClick = async () =>{
        const params = {
            access: 'auth',
            method: API.ADD_CART.type,
            url: API.ADD_CART.url,
            reqBody: {
                item_id: data?.item_id,
                item_name: data?.item_name,
                quantity: qty,
                note: note,
                item_image: data?.item_image[0],
                menu_slug: data?.menu_slug
            }
        }
        const response = await Api(params);
        if(response.status){
            toast(response.message)
        }else{
            toast.error(response.message)
        }

    }


    

    return (
        <div className="h-screen w-full">
            <ToastContainer autoClose={1000} />
            <div className="h-screen container mx-auto p-5 pt-20 pb-24  overflow-auto no-scrollbar">
                <div className='flex flex-col md:flex-row justify-center '>
                    <div className='flex flex-col xl:flex-row p-2'>
                        <div className='w-full'>
                            <div className='w-full p-5'>
                                <img className="w-full" src={selectedImage} alt="" />
                            </div>
                            <div className='grid grid-cols-6'>
                                {images?.map((img,i)=>(
                                    <img key={i} className="border p-2 rounded-md hover:border-2 cursor-pointer" src={img} alt="" onClick={()=>{setSelectedImage(img)}}/>
                                ))}
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex justify-center items-center min-h-12 w-full border-t border-b'>
                                <div className='text-xl font-bold'>{data?.item_name}</div>
                            </div>
                            
                            <div className='flex justify-center items-center min-h-12 border-b'>
                                <div className='text-xl font-bold'>Rp. {Intl.NumberFormat('en-ID').format(data?.current_price)}</div>    
                            </div>
                            <div className='mt-5 mb-5 max-h-[20rem] overflow-auto no-scrollbar border-b pb-5'>
                                <div className="text-sm" dangerouslySetInnerHTML={{__html: data?.item_description}}/>
                            </div>
                            
                        </div>
                    </div>

                    <div className='w-full border rounded-xl p-5'>
                        <div className='font-bold mb-5'>Atur jumlah dan catatan</div>
                        <div className='flex items-center gap-3 mb-5'>
                            <img className="w-1/12" src={selectedImage} alt="" />
                            <div>{data?.item_name}</div>
                        </div>
                        <div className='flex items-center gap-5'>
                            <div className='flex'>
                                <button className='btn rounded-none' style={{backgroundColor:`${primaryColor}`}} onClick={()=>{
                                    if(qty <= 0 ) {
                                        setQty(0)
                                    }else{
                                        setQty(qty - 1)
                                    }
                                }}>-</button>   
                                <input type="number"  className='input w-20 rounded-none text-center' value={qty} onChange={(e)=>setQty(e.target.value)}/>
                                <button className='btn rounded-none' style={{backgroundColor:`${primaryColor}`}} onClick={()=>{
                                    if(qty >= data?.menu_current_quantity ) {
                                        setQty(data?.menu_current_quantity)
                                    }else{
                                        setQty(qty + 1)
                                    }
                                }}>+</button>
                            </div>
                            <div>stock : {data?.menu_current_quantity} pcs</div>
                        </div>
                        <div className='text-sm mt-3'>min pembelian 1 pcs</div>
                        <div>
                            <textarea  className='textarea w-full mt-3 bordered' placeholder='catatan ...' value={note} onChange={(event)=>{
                                setNote(event.target.value)
                            }}>{note}</textarea>
                        </div>
                        <div className='flex justify-between mb-5 mt-5'>
                            <div>Sub total</div>
                            <div className='font-bold'>Rp. {Intl.NumberFormat('en-ID').format(total)}</div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className={`btn hover:opacity-50 text-white`} style={{backgroundColor:`${primaryColor}`}} onClick={handleAddOnClick}>+ Keranjang</div>
                            <div className={`btn hover:opacity-50 text-white`} style={{backgroundColor:`${primaryColor}`}}>beli langsung</div>
                        </div>
                    </div>

                </div>
                

            </div>
        </div>
    )
}

export default Voucher