import React, { useState, setState, useCallback, useRef, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import axios from 'axios';
import { Modal, Input, Button } from 'react-daisyui';
import { session, setSession, setStore } from '../../utils/AuthSession'
import { Navigate, useNavigate } from 'react-router-dom'


function Login() {
    const [phone, setPhone] = useState('')
    const [messages, setMessages] = useState()
    const [wintoken, setWintoken] = useState(false)
    const [token, setToken] = useState()
    const [tokenMessage, setTokenMessage] = useState(null)
    const [AuthSession, setAuthSession] = useState(session())
    const ref = useRef(null);
    const history = useNavigate()
    // const ref = useRef < HTMLDialogElement > (null);
    const handleShow = useCallback(() => {
        ref.current?.showModal();
    }, [ref]);

    if (AuthSession) {
        return (
            <Navigate to="/" replace={true} />
        )
    }


    const handleButtonClick = async () => {
        const params = {
            access: 'none',
            method: API.REQUEST_TOKEN.type,
            url: API.REQUEST_TOKEN.url,
            reqBody: {
                lid: phone
            }

        }
        const response = await Api(params);
        console.log(response)
        if (response.status) {
            setWintoken(true)
            handleShow();
            setMessages(response.message)
            setToken('')
        } else {
            setMessages(null)

        }
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value)
    }

    const handleTokenChange = (e) => {
        setToken(e.target.value)
    }

    const handleTokenSubmit = async () => {
        const params = {
            access: 'none',
            method: API.LOGIN_TOKEN.type,
            url: API.LOGIN_TOKEN.url,
            reqBody: {
                lid: phone,
                token: token
            }
        }

        const response = await Api(params);
        // console.log(response)
        if (!response.status) {
            setTokenMessage(response.message)

        } else {
            setTokenMessage(false)
            setToken('')
            setWintoken(false)

            setSession(response.data)
            // get Store
            const params2 = {
                access: 'auth',
                method: API.STORE.type,
                url: API.STORE.url,
                reqBody: {
                    partner_id: response.data.partner_id
                }
            }
            const response2 = await Api(params2);
            // console.log(response2)
            if (response2.status) {
                setStore(response.data)
                // console.log('store',response2.data)
            }


            // go Auth
            history(0)
        }

    }

    return (
        <div className="h-screen w-full">
            <div className="h-screen mx-auto max-w-sm">
                <div className="p-12">
                    <p className="text-4xl pt-10 text-yellow-500 font-bold">
                        Welcome <br />
                        Back
                    </p>
                    <p className="text-md py-3 text-gray-400 font-semibold">Sign in to continue</p>
                </div>
                <div className="mx-12 p-3 rounded-xl shadow-sm bg-gray-900">
                    <div className="p-3 mx-6 border-b border-gray-500">
                        <input placeholder="Phone or email"
                            className="bg-transparent text-yellow-500 w-full focus:outline-none focus:rang"
                            type="text"
                            value={phone}
                            onChange={handlePhoneChange} />
                    </div>
                </div>

                <div className="w-full p-12">
                    <button className=" bg-yellow-500 p-3 rounded-3xl w-full h-full hover:bg-yellow-600" onClick={handleButtonClick}> Send Token</button>
                </div>

                {messages && (
                    <div className='text-center'>
                        {messages}
                    </div>
                )}
            </div>
            <Modal ref={ref}>
                <Modal.Body>
                    <div className='text-center pt-10'>{messages}</div>
                    <div className="flex w-full component-preview p-4 items-center justify-center gap-2  font-sans flex-col">
                        <Input
                            bordered={true}
                            color='primary'
                            className='border-yellow-400 text-center'
                            value={token}
                            type="number"
                            onChange={handleTokenChange}
                        />
                        {tokenMessage && (
                            <div>
                                {tokenMessage}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Actions >
                    <form method="dialog" className='flex justify-center gap-2'>
                        <a className='btn' onClick={handleTokenSubmit}>Submit</a>
                        <Button >Close</Button>
                    </form>
                </Modal.Actions>
            </Modal>

        </div >




    )
}

export default Login