import React, { useState, setState, useCallback, useRef, useEffect } from 'react'
import { session, setSession } from '../../utils/AuthSession'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'

function Index() {
    const { cid, sid,lid } = useParams();
    const [AuthSession, setAuthSession] = useState(session())

    const autoLogin = async () => {
        const params = {
            access: 'none',
            method: API.REGISTER.type,
            url: API.REGISTER.url,
            reqBody: {
                cid: cid,
                sid: sid,
                lid: lid
            }

        }
        const response = await Api(params);
        console.log(response)
        if(response.status){
            setSession(response.data)
            window.location.href = '/';
           
        }
        
    }

    useEffect(()=>{
        if(session()){
            window.location.href = '/';
            // return (
            //     <Navigate to="/" replace={true} />
            // )
        }else{
            autoLogin()  
        }
    },[])
    

    return (
        <div></div>
    )
}

export default Index