import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button, Pagination, Card } from 'react-daisyui'
import ProfilUnknow from '../../assets/profile-unknown.png'
import { session } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import moment from 'moment'
import DefaultVoucher from "../../assets/default-voucher.png"
import DefaultProduct from "../../assets/default-product.jpg"
import { QRCodeSVG } from 'qrcode.react';
import QRCode from "react-qr-code";
import Cookie from 'js-cookie'
import { ThemeContext } from '../../context/ThemeContext'
const CryptoJS = require("crypto-js");


function Redeem() {
    const [authSession, setAuthSession] = useState(session())
    const [voucher, setVoucher] = useState([])
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [data, setData] = useState()
    const ref = useRef()
    const ref2 = useRef()
    const [qrcode, setQrcode] = useState()

    const {primaryColor} = useContext(ThemeContext)

    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        const params = {
            access: 'auth',
            method: API.REDEEM_VOUCHER.type,
            url: API.REDEEM_VOUCHER.url,
            reqBody: {
                page: page,
                rows: 5
            }
        }

        const response = await Api(params);
        if (response.status) {
            setVoucher(response.data.data)
            setLastPage(response.data.lastPage)
            setPage(response.data.page)
        }
    }

    const handlePrev = () => {
        setPage(page > 1 ? page - 1 : 1)
    }

    const handleNext = () => {
        setPage(page < lastPage ? page + 1 : lastPage)
    }

    useEffect(() => {

    }, [page])

    const showQRCode = (v) => {
        ref2.current.classList.remove('hidden');
        ref2.current.classList.add('flex');
        const mv_id = v.member_voucher_id.toString()
        // console.log(v.voucher.partner.server_id)
        const enc_mvid = CryptoJS.AES.encrypt(mv_id, v.voucher.partner.server_id).toString();
        console.log(enc_mvid)

        setQrcode(enc_mvid)

    }

    const showVoucher = (v) => {
        ref.current.classList.remove('hidden');
        ref.current.classList.add('flex');
        setData(v)

    }



    const handleClose = () => {
        ref.current.classList.add('hidden');
        ref.current.classList.remove('flex');
        ref2.current.classList.add('hidden');
        ref2.current.classList.remove('flex');
    }
    console.log(voucher)
    return (
        <div className="h-screen w-full">
            <div className="container h-screen mx-auto p-5 pt-16 pb-24">
                <div className='text-xl font-bold text-center pt-10 pb-10'>
                    <h1 className="text-xl font-bold mb-2">Voucher Anda</h1>
                    <div className='overflow-auto no-scrollbar h-[72dvh]'>
                        
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 pt-5  m-2 items-start'>
                            {voucher.map((v, i) => (
                                <div key={i} className='flex flex-col border rounded-xl p-5 bg-white text-black h-[32rem] drop-shadow'>
                                    <div className='flex h-[9rem] justify-center'>
                                        <img className="h-auto object-contain max-w-full" src={v?.voucher?.product_image ? v?.voucher?.product_image : DefaultVoucher} alt="" />
                                    </div>

                                    <div className='flex justify-center items-center text-md font-bold text-slate-700'>
                                        {v?.voucher?.name}
                                    </div>

                                    <div className='overflow-auto no-scrollbar h-[10rem] text-xs text-justify pt-1'>
                                        {v?.voucher?.description}
                                    </div>

                                    <div className='flex justify-between'>
                                        <div className='text-slate-500 font-semibold'>Berlaku sampai</div>
                                        <div className='text-blue-600 font-semibold'>{moment(v?.expire_date).format('DD/MM/YY')} </div>
                                        
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='text-slate-500 font-semibold'>Penyedia</div> 
                                        <div className='text-blue-600 font-semibold'>{v?.voucher?.partner?.name}</div>
                                    </div>
                                    
                                    
                                    <div className='flex justify-between mt-3'>
                                        <Button
                                            active={false}
                                            className='border-none text-white hover:opacity-50 normal-case min-h-0 h-10 rounded-xl'
                                            style={{backgroundColor: `${primaryColor}`}}
                                            onClick={() => showVoucher(v)}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            className='border-none text-white hover:opacity-50 normal-case min-h-0 h-10 rounded-xl'
                                            style={{backgroundColor: `${primaryColor}`}}
                                            onClick={() => showQRCode(v)}
                                        >
                                            Tukar
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <button className='btn' disabled={page === 1 ? true : false} onClick={handlePrev}>prev</button>
                        <button className='btn' disabled={page === lastPage ? true : false} onClick={handleNext}>next</button>
                    </div>
                </div>
            </div>

            <div ref={ref} aria-hidden="true" className="hidden bg-slate-800 bg-opacity-50 overflow-x-hidden h-screen  overflow-y-auto fixed h-modal top-0 pt-10 justify-center md:h-full  md:pt-0 left-0 right-0 md:inset-0 z-50 ">
                <div className="relative w-full max-w-md px-4 h-full md:h-auto">
                    {/* <!-- Modal content --> */}
                    <div className="bg-white rounded-tl-lg rounded-tr-lg shadow relative">
                        <div className="flex justify-between p-2 border-b items-center">
                            <div className="text-slate-600 text-lg pl-2 font-bold">
                                Lihat Voucher
                            </div>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                onClick={handleClose}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </div>

                    </div>
                    <div className='bg-white text-slate-900 h-4/5 md:4/5  rounded-bl-lg rounded-br-lg p-3 overflow-auto no-scrollbar' >
                        {/* <div>
                            <img src={data?.voucher?.voucher_image ? data?.voucher?.voucher_image : DefaultVoucher} alt="" />
                        </div> */}
                        <div className='flex justify-between mt-3'>
                            <div className='font-bold text-indigo-700'>Berlaku sampai</div>
                            <div className='font-bold text-slate-600'>{data?.expire_date ? moment(data.expire_date).format('DD/MM/YY HH:MM:ss') : ``}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='font-bold text-indigo-700'>Pemilik  voucher</div>
                            <div className='font-bold text-slate-600'>{data?.member?.firstname ? `${data?.member?.firstname} ${data.member?.lastname}` : data?.member?.phone}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='font-bold text-indigo-700'>Voucher</div>
                            <div className='font-bold text-slate-600'>{data?.voucher?.name}</div>
                        </div>
                        <div className='p-3'>
                            <img className='rounded-xl shadow-md shadow-slate-600' src={data?.voucher?.product_image ? data?.voucher?.product_image : DefaultProduct} alt="" />
                        </div>
                        <div className='flex justify-between'>
                            <div className='font-bold text-indigo-700'>SKU</div>
                            <div className='font-bold text-slate-600'>{data?.voucher?.sku}</div>
                        </div>
                        <div className='p-3 border-2 rounded-lg shadow-md shadow-slate-600'>
                            {data?.voucher?.description}
                        </div>
                        <div className='flex justify-between mt-3'>
                            <div className='font-bold text-indigo-700'>Dikeluarkan oleh</div>
                            <div className='font-bold text-slate-600'>{data?.voucher?.partner?.name}</div>
                        </div>
                        <div>
                            <div className='font-bold text-indigo-700'>Alamat penukaran:</div>
                            {data?.voucher?.partner?.redeem_merchants?.map((d, i) => (
                                <div key={i} className='mt-2 p-3 border-2 rounded-lg shadow-md shadow-slate-600'>
                                    <div>{d.name}</div>
                                    <div>{d.address}</div>
                                </div>
                            ))}
                        </div>



                    </div>
                </div>
            </div>

            <div ref={ref2} aria-hidden="true" className="hidden bg-slate-800 bg-opacity-50 overflow-x-hidden h-screen  overflow-y-auto fixed h-modal top-0 pt-10 justify-center md:h-full  md:pt-0 left-0 right-0 md:inset-0 z-50 ">
                <div className="relative w-full max-w-md px-4 h-full md:h-auto">
                    {/* <!-- Modal content --> */}
                    <div className="bg-white rounded-tl-lg rounded-tr-lg shadow relative">
                        <div className="flex justify-between p-2 border-b items-center">
                            <div className="text-slate-600 text-lg pl-2 font-bold">
                                Tukar Voucher
                            </div>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                onClick={handleClose}
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </div>

                    </div>
                    <div className='bg-white text-slate-900  rounded-bl-lg rounded-br-lg p-3 flex justify-center' >
                        <QRCodeSVG
                            value={qrcode}
                            size={300}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"H"}
                            includeMargin={false}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Redeem