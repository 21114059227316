import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import axios from "axios"

function MidT(props) {
    useEffect(() => {
        try {
            if (window.snap) {
                const payButton = document.getElementById('pay-button');
                window.snap.pay(props.token, {
                    onSuccess: function (result) {
                        console.log('success', result);
                        props.onSuccess(result)
                    },
                    onPending: function (result) {
                        console.log('pending', result);
                        props.onPending(result)
                    },
                    onError: function (result) {
                        console.log('error', result);
                        props.onError(result)
                    },
                    onClose: function () {
                        console.log('customer closed the popup without finishing the payment');
                        props.onClose()
                    }
                });
            } else {
                console.error('Snap.js is not loaded yet');
            }
        } catch (error) {

        }

    }, [])
    const handleOnClick = async () => {

    }
    return (
        <>
        </>

    )
}

export default MidT