import React, {useContext} from 'react'
import { ThemeContext } from '../../context/ThemeContext'
function CategoryNav(props) {
    // console.log('props',props.defaultCategory)
    const {primaryColor,primaryColorHover} = useContext(ThemeContext)

    return (
        <div className="flex w-full h-16  border border-none top-0">
            <div className="flex mt-1">
                <div className="flex h-full grid-cols-6">
                    {props.category.map((c, i) => (
                        <button key ={i} type="button" className={`inline-flex flex-col items-center justify-center px-5  hover:opacity-50 group text-2xl text-white`} style={{backgroundColor:`${primaryColor}`}}
                            onClick={() => props.onClick(c)}
                        >
                            <span className="font-normal text-sm">{c}</span>
                        </button>
                    ))}


                </div>
            </div>

        </div>
    )
}

export default CategoryNav