"use client";
"use strict";
import React, { useState, useEffect, useContext } from 'react'
import { session } from '../../utils/AuthSession'
import { API } from '../../utils/api'
import { Api } from '../../utils/apiServer'
import DefaultVoucher from "../../assets/default-voucher.png"
import { AiOutlineHistory } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import { Card } from "react-daisyui"
import { ThemeContext } from "../../context/ThemeContext";
import { Navigate } from 'react-router-dom'
import {
    useNavigate
} from "react-router-dom";
import merchant from '../../assets/merchant.png'
import history from '../../assets/history.png'

const Home = () => {
    const [authSession, setAuthSession] = useState(session())
    const [profile, setProfile] = useState(null)
    const { primaryColor, primaryColorHover } = useContext(ThemeContext)
    const navigate = useNavigate();

    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        const params = {
            access: 'auth',
            method: API.PROFILE.type,
            url: API.PROFILE.url,
            reqBody: {

            }

        }
        const response = await Api(params);
        if (response) {
            setProfile(response.data)
            // console.log(response.data)

        }
    }
    console.log(profile?.member_voucher)
    return (
        <div className="h-screen w-full">
            <div className="h-screen mx-auto max-w-sm p-5 pt-8 pb-24">
                <div className='flex flex-col h-full overflow-auto no-scrollbar'>
                    <div className='bordered  rounded-md  p-3 text-gray-600 text-center ml-10 mr-10'>
                        <div className='text-md font-semibold'>
                            Hallo, {profile?.firstname ? profile.firstname : authSession.user.phone}
                        </div>
                        <div className='text-xs'>
                            Point anda :
                        </div>
                        <div className="text-2xl font-bold">{profile?.point?.point ? profile.point.point : 0} point</div>
                    </div>
                    <div className='flex gap-3 bg-white p-1 rounded-md shadow-md justify-center' >
                        <div className='flex flex-col justify-center items-center' onClick={() => navigate('/history')}>
                            <div className={`inline-flex flex-col items-center justify-center px-3 hover:opacity-50 group text-2xl  h-14 w-14 rounded-full text-white hover:cursor-pointer`} style={{ backgroundColor: `${primaryColor}` }}

                            >
                                <img src={history} alt="" />
                            </div>
                            <span className="text-xs">Riwayat</span>
                        </div>


                        <div className='flex flex-col justify-center items-center' onClick={() => navigate('/merchant')}>
                            <div className={`inline-flex flex-col items-center justify-center px-3 hover:opacity-50 group text-2xl  h-14 w-14 rounded-full text-white hover:cursor-pointer`} style={{ backgroundColor: `${primaryColor}` }}

                            >
                                <img src={merchant} alt="" />
                            </div>
                            <span className="text-xs">Merchant</span>
                        </div>
                    </div>
                    <div className='bg-white mt-1 rounded-md shadow-md pb-2'>
                        <div className='text-center text-xs mt-3 '>Voucher anda:</div>
                        <div className='flex justify-center items-center'>
                            {profile?.member_voucher?.length === 0 ? (
                                <div className='text-sm font-bold'>Anda tidak memiliki voucher</div>
                            ) : (
                                profile?.member_voucher?.map((mv, i) => (
                                    <div key={i} >
                                        <img src={mv.voucher.voucher_image === null ? DefaultVoucher : mv.voucher.voucher_image} alt="" />
                                    </div>
                                ))
                            )}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Home